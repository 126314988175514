import Button from "components/form/Button";
import Card from "components/Layout/Card";
import AbstractRegistrationPass from "components/V2ForNewAbstractFlow/AbstractRegistrationPass";
import FollowUpsDetails from "components/V2ForNewAbstractFlow/FollowUpsDetails";
import ReviewReportDetails from "components/V2ForNewAbstractFlow/ReviewReportDetails";
// import RevisionPaperForm from "components/V2ForNewAbstractFlow/RevisionPaperForm";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  //   fetchJournalPapersDetails,
  fetchSubmittedAbstractsDetails,
} from "store/slices";
import {
  eventAbstarctPath,
  eventPlagiarismDocumentPath,
  icons,
} from "utils/constants";
import {
  downloadFile,
  formatDate,
  generatePreSignedUrl,
  titleCaseString,
} from "utils/helpers";
import "./NewViewPaper.scss";
import RevisionPaperForm from "../RevisionPaperForm";

const NewViewPaper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { paperType, paperId } = params;

  const [isSubmittedPaperDetail, setIsSubmittedPaperDetail] = useState(false);
  const [isAddRevisionAbstract, setIsAddRevisionAbstract] = useState(false);
  const [isRevisionDetails, setIsRevisionDetails] = useState(null);
  const [isReviewReport, setIsReviewReport] = useState(false);
  const [isFollowUps, setIsFollowUps] = useState(false);
  const [expandedIndexes, setExpandedIndexes] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [data, setData] = useState({
    paperTitleName: "",
    paperAbstractDetail: "",
    status: "1",
    paper: "",
    paperDate: "",
    plagiarismDate: "",
    plagiarismStatus: "",
    plagiarismComment: "",
    plagiarismDocument: "",
    plagiarismExpired: "",
    revisionStatus: "",
    plagiarismAcceptedDate: "",
    isRepublish: "",
    republishDate: "",
    reviewDate: "",
    reviewComment: "",
    acceptanceDate: "",
    acceptanceStatus: "",
    registrationDate: "",
    acceptanceLetter: "",
    is_remodify: "",
    reviewing_file: "",
    is_registered_status: "0",
  });

  //   const getPaperDetails = async () => {
  //     const response = await dispatch(fetchJournalPapersDetails(id));
  //     const { paperImage, submittedPapersStatus } = response?.data;
  //   };

  const getAbstractDetails = async () => {
    const response = await dispatch(fetchSubmittedAbstractsDetails(paperId));
    const {
      abstract_path,
      paperTitle,
      paper_abstract,
      revision_details,
      submittedPapersStatus,
      is_event_registered,
      revision_reasons,
    } = response?.data;
    handelData(
      abstract_path,
      paperTitle,
      paper_abstract,
      revision_details,
      submittedPapersStatus,
      is_event_registered,
      revision_reasons
    );
  };

  const handelData = (
    file,
    paperTitle,
    paper_abstract,
    revision_details,
    paperStatusData,
    is_event_registered = "",
    revision_reasons
  ) => {
    const {
      paper_submitted_date,
      paper_submitted_status,
      plagiarism_comment,
      plagiarism_date,
      plagiarism_status,
      plagiarism_document,
      plagiarism_expired,
      plagiarism_accepted_date,
      revision_status,
      is_republish_by_admin,
      republish_date,
      review_date,
      review_comment,
      acceptance_date,
      acceptance_status,
      registration_date,
      registration_acceptance_letter,
      is_remodify,
      reviewing_file,
      is_registered_status,
    } = paperStatusData;
    setData({
      paper: file,
      paperTitleName: paperTitle,
      paperAbstractDetail: paper_abstract,
      status: +paper_submitted_status || 1,
      paperDate: paper_submitted_date,
      revisionDetails: revision_details,
      isRepublish: is_republish_by_admin,
      republishDate: republish_date,
      plagiarismDate: plagiarism_date,
      plagiarismStatus: plagiarism_status,
      plagiarismComment: plagiarism_comment,
      plagiarismDocument: plagiarism_document,
      plagiarismExpired: plagiarism_expired,
      plagiarismAcceptedDate: plagiarism_accepted_date,
      revisionStatus: revision_status,
      reviewDate: review_date,
      reviewComment: review_comment,
      acceptanceDate: acceptance_date,
      acceptanceStatus: acceptance_status,
      registrationDate: registration_date,
      acceptanceLetter: registration_acceptance_letter,
      is_remodify: is_remodify,
      reviewing_file: reviewing_file,
      is_event_registered: is_event_registered,
      is_registered_status: is_registered_status,
      revisionReasons: revision_reasons,
    });
  };

  useEffect(() => {
    if (paperType === "paper") {
      //   getPaperDetails();
    } else if (paperType === "abstract") {
      getAbstractDetails();
    } else {
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    paperTitleName,
    paperAbstractDetail,
    status,
    paper,
    paperDate,
    revisionDetails,
    // plagiarismDate,
    plagiarismStatus,
    plagiarismComment,
    plagiarismDocument,
    plagiarismExpired,
    plagiarismAcceptedDate,
    revisionStatus,
    revisionReasons,
    // isRepublish,
    // republishDate,
    // reviewDate,
    // reviewComment,
    // acceptanceDate,
    // acceptanceStatus,
    // registrationDate,
    // acceptanceLetter,
    // is_remodify,
    // reviewing_file: reviewingFile,
    // is_event_registered,
    // is_registered_status,
  } = data;

  const plagiarismAccepted = +plagiarismStatus === 1;
  const abstractText = paperAbstractDetail
    ?.replace(/<\/?p>/g, "")
    ?.replace(/<\/?span[^>]*>/g, "")
    ?.trim();

  const abstractWords = abstractText?.split(" ")?.filter((word) => word);
  const maxWords = 70;
  const toggleRevisionReadMore = (index) => {
    setExpandedIndexes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <Card className="d-flex align-items-center p-1 unset-br mb-4">
        <span
          className="d-flex ps-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={icons.leftArrow} alt="back" className="h-21 me-3 pointer" />
          Submitted Abstract Track
        </span>
      </Card>

      <Card className="new-view-paper-container cpt-40 cpb-40 cps-40 cpe-40">
        {/* Abstract */}
        <div className="d-flex justify-content-start align-items-center">
          <div className="steps-block active">
            <img src={icons?.RightIcon} alt="right" />
          </div>
          <div>
            <div className="text-16-500-20 color-new-car d-flex align-items-center gap-2">
              Abstract
              <i className="bi bi-chevron-down" style={{ fontSize: "15px" }} />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-start">
          <div className="sparator-block">
            <span className="hr-line" />
          </div>

          {revisionDetails?.length > 0 ? (
            <>
              <div className="w-100">
                <div className="submitted-paper-block border br-5 w-100 cmb-8">
                  <div
                    className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                    onClick={() => {
                      setIsSubmittedPaperDetail(!isSubmittedPaperDetail);
                    }}
                  >
                    <span>Submitted Paper</span>
                    <i
                      className="bi bi-chevron-down"
                      style={{ fontSize: "15px" }}
                    />
                  </div>
                  {isSubmittedPaperDetail && (
                    <div className="cps-24 cpt-12 cpe-24 cpb-12">
                      {paperTitleName && (
                        <div className="cmb-14">
                          {titleCaseString(paperTitleName)}
                        </div>
                      )}
                      {paperAbstractDetail && (
                        <div className="cmb-16 text-14-300-24 color-black-olive">
                          <div>
                            {isExpanded ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: paperAbstractDetail,
                                }}
                              />
                            ) : (
                              <>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: paperAbstractDetail
                                      ?.replace(/<\/?p>/g, "")
                                      ?.replace(/<\/?span[^>]*>/g, "")
                                      ?.split(" ")
                                      ?.slice(0, maxWords)
                                      ?.join(" "),
                                  }}
                                />

                                {abstractWords?.length > maxWords && (
                                  <>
                                    {"... "}
                                    <span
                                      onClick={toggleReadMore}
                                      className="text-14-500-23 color-new-car pointer"
                                    >
                                      Read More
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </div>

                          {isExpanded && (
                            <span
                              onClick={toggleReadMore}
                              className="text-14-500-23 color-new-car pointer"
                            >
                              Read Less
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {revisionDetails?.map((details, index) => {
                  const { abstract_path, revision_date, paper_abstract } =
                    details;
                  const isOpen = isRevisionDetails === index;
                  const isExpanded = expandedIndexes[index];

                  return (
                    <React.Fragment key={index}>
                      <div
                        className="revision-block border br-5 w-100 cmb-8"
                        key={index + 1}
                      >
                        <div
                          className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                          onClick={() => {
                            setIsRevisionDetails(isOpen ? null : index);
                          }}
                        >
                          <span>Revision {index + 1}</span>
                          <i
                            className={`${
                              isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>

                        {isOpen && (
                          <div className="cps-24 cpt-12 cpe-24 cpb-12">
                            {revision_date && (
                              <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 cmb-14">
                                <img src={icons.calendarCheck} alt="calendar" />
                                {formatDate(revision_date)}
                              </div>
                            )}
                            {paper_abstract && (
                              <div className="cmb-16 text-14-300-24 color-black-olive">
                                <div>
                                  {isExpanded ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: paper_abstract,
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: paper_abstract
                                            ?.replace(/<\/?p>/g, "")
                                            ?.replace(/<\/?span[^>]*>/g, "")
                                            ?.split(" ")
                                            ?.slice(0, maxWords)
                                            ?.join(" "),
                                        }}
                                      />
                                      {paper_abstract?.split(" ")?.length >
                                        maxWords && (
                                        <>
                                          {"... "}
                                          <span
                                            onClick={() =>
                                              toggleRevisionReadMore(index)
                                            }
                                            className="text-14-500-23 color-new-car pointer"
                                          >
                                            Read More
                                          </span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>

                                {isExpanded && (
                                  <span
                                    onClick={() =>
                                      toggleRevisionReadMore(index)
                                    }
                                    className="text-14-500-23 color-new-car pointer"
                                  >
                                    Read Less
                                  </span>
                                )}
                              </div>
                            )}
                            <div
                              className="cmb-16 text-16-500-20 color-7cff pointer"
                              onClick={async () => {
                                if (abstract_path) {
                                  let downloadContent = "";
                                  if (paperType === "abstract") {
                                    downloadContent =
                                      await generatePreSignedUrl(
                                        abstract_path,
                                        eventAbstarctPath
                                      );
                                  }
                                  dispatch(downloadFile(downloadContent));
                                }
                              }}
                            >
                              Download Abstract File
                            </div>
                          </div>
                        )}
                      </div>

                      {isAddRevisionAbstract && (
                        <div className="revision-block border br-5 w-100 cmb-8">
                          <div className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12">
                            <span>{`Revision ${index + 2}`}</span>
                            <i
                              className={`${"bi bi-chevron-up"}`}
                              style={{ fontSize: "15px" }}
                            />
                          </div>

                          <RevisionPaperForm
                            abstractId={paperId}
                            onHide={() => {
                              setIsAddRevisionAbstract(false);
                            }}
                            handleSuccess={() => {
                              getAbstractDetails();
                            }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          ) : isAddRevisionAbstract ? (
            <>
              <div className="w-100">
                {/* <div className="submitted-paper-block border br-5 cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff w-100 cmb-8">
                  <div className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car">
                    <span>Submitted Paper</span>
                    <i
                      className="bi bi-chevron-down"
                      style={{ fontSize: "15px" }}
                    />
                  </div>
                </div> */}

                <div className="revision-block border br-5  w-100 cmb-8">
                  <div
                    className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                    // onClick={() => {
                    //   setIsRevisionDetails(!isRevisionDetails);
                    // }}
                  >
                    <span>Revision 1</span>
                    <i
                      className={`${
                        // isRevisionDetails
                        // ? "bi bi-chevron-up"
                        // :
                        "bi bi-chevron-down"
                      }`}
                      style={{ fontSize: "15px" }}
                    />
                  </div>

                  {/* {isRevisionDetails && ( */}
                  <RevisionPaperForm
                    abstractId={paperId}
                    onHide={() => {
                      setIsAddRevisionAbstract(false);
                    }}
                    handleSuccess={() => {
                      getAbstractDetails();
                    }}
                  />
                  {/* )} */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="cmt-14 cmb-19">
                {paperDate && (
                  <div className="date-block d-flex gap-2 align-items-center cmb-16 color-black-olive text-14-300">
                    <img src={icons.calendarCheck} alt="calendar" />
                    {formatDate(paperDate)}
                  </div>
                )}
                <div className="cmb-16 text-14-300-24 color-black-olive">
                  You have successfully submitted abstract !!
                </div>
                <div
                  className="cmb-16 text-16-500-20 color-7cff pointer"
                  onClick={async () => {
                    if (paper) {
                      let downloadContent = "";
                      if (paperType === "abstract") {
                        downloadContent = await generatePreSignedUrl(
                          paper,
                          eventAbstarctPath
                        );
                      }
                      dispatch(downloadFile(downloadContent));
                    }
                  }}
                >
                  Download Abstract File
                </div>
              </div>
            </>
          )}
        </div>

        {/* Plagiarism */}
        <div className="d-flex justify-content-start align-items-center">
          <div
            className={`steps-block ${status >= 2 ? "active" : "not-active"}`}
          >
            {plagiarismAccepted ? (
              <img src={icons?.RightIcon} alt="right" />
            ) : (
              "2"
            )}
          </div>
          <div>
            <div
              className={`text-16-500-20 ${
                status >= 2 ? "color-new-car" : "color-6866"
              }`}
            >
              Plagiarism{" "}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-start">
          <div className="sparator-block">
            <span className="hr-line" />
          </div>
          <div className="cpt-14 cpb-14 w-100">
            {paperDate && (
              <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                <img src={icons.calendarCheck} alt="calendar" />
                {formatDate(paperDate)}
              </div>
            )}
            {/* {plagiarismDate && (
              <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                <img src={icons.calendarCheck} alt="calendar" />
                {formatDate(plagiarismDate)}
              </div>
            )} */}
            {status < 2 && !plagiarismStatus && (
              <div className="cmb-14 text-14-400-18 color-black-olive">
                Under processing
              </div>
            )}

            {revisionReasons?.length > 0
              ? revisionReasons?.map((revComment, index) => {
                  return (
                    <div
                      className="text-14-300 color-black-olive cmb-16 w-100"
                      key={index}
                    >
                      <div className="d-flex gap-2 w-100">
                        <div className="color-new-car text-nowrap">
                          {`Revision ${index + 1}:`}
                        </div>
                        <div className="d-flex flex-grow-1">
                          {titleCaseString(revComment?.revision_comment)}
                        </div>
                      </div>
                    </div>
                  );
                })
              : ["1", "3"].includes(plagiarismStatus) &&
                plagiarismComment && (
                  <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                    {titleCaseString(plagiarismComment)}
                  </div>
                )}
            {/* {plagiarismComment && (
              <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                <span className="color-new-car">Revision 1:</span>
                {titleCaseString(plagiarismComment)}
              </div>
            )} */}
            {plagiarismStatus === "2" && revisionStatus === "2" && (
              <div className="d-flex">
                <Button
                  text={`+ Add Abstract`}
                  btnStyle="primary-dark"
                  className="text-15-500 cps-15 cpe-15 h-30"
                  onClick={() => {
                    setIsAddRevisionAbstract(true);
                  }}
                />
              </div>
            )}
            {plagiarismStatus === "1" && (
              <>
                {plagiarismComment && (
                  <div className="text-14-300 color-black-olive">
                    {titleCaseString(plagiarismComment)}
                  </div>
                )}
                {plagiarismAcceptedDate && (
                  <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 cmt-14">
                    <img src={icons.calendarCheck} alt="calendar" />
                    {formatDate(plagiarismAcceptedDate)}
                  </div>
                )}
                {plagiarismExpired && plagiarismExpired !== 0 && (
                  <div
                    className="cmb-16 cmt-14 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer"
                    onClick={async () => {
                      if (paper) {
                        let downloadContent = "";
                        if (paperType === "abstract") {
                          downloadContent = await generatePreSignedUrl(
                            plagiarismDocument,
                            eventPlagiarismDocumentPath
                          );
                        }
                        dispatch(downloadFile(downloadContent));
                      }
                    }}
                  >
                    Download Report{" "}
                    <span className="text-14-300 color-black-olive">
                      {`(Report will be available ${plagiarismExpired} days)`}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* Review */}
        <div className="d-flex justify-content-start align-items-center">
          <div
            className={`steps-block ${
              plagiarismAccepted && status >= 2 ? "active" : "not-active"
            }`}
          >
            3
          </div>
          <div>
            <div
              className={`text-16-500-20 ${
                plagiarismAccepted && status >= 2
                  ? "color-new-car"
                  : "color-6866"
              }`}
            >
              Review
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="sparator-block">
            <span className="hr-line" />
          </div>
          {plagiarismAccepted && status >= 2 ? (
            <>
              <div>
                {status >= 2 && (
                  <div className="text-14-400-17 color-6866 cmb-18 cmt-16">
                    Review is under processing
                  </div>
                )}

                {status === 3 && (
                  <div className="w-100">
                    <div className="review-block border br-5  w-100 cmb-8">
                      <div
                        className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                        onClick={() => {
                          setIsReviewReport(!isReviewReport);
                        }}
                      >
                        <span>Your Review Report</span>
                        <i
                          className={`${
                            isReviewReport
                              ? "bi bi-chevron-up"
                              : "bi bi-chevron-down"
                          }`}
                          style={{ fontSize: "15px" }}
                        />
                      </div>

                      {isReviewReport && <ReviewReportDetails />}
                    </div>
                    <div className="text-20-400-25 color-1717 cmb-13">
                      Your score is 2.2 out of 5 (Good)
                    </div>
                    <div className="text-16-300-20 color-1717 cmb-19">
                      Review has been completed !!
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="w-100 cpt-20 cpb-20"></div>
          )}
        </div>

        {/* Acceptance */}
        <div className="d-flex justify-content-start align-items-center">
          <div
            className={`steps-block ${status >= 4 ? "active" : "not-active"}`}
          >
            4
          </div>
          <div>
            <div
              className={`text-16-500-20 ${
                status >= 4 ? "color-new-car" : "color-6866"
              }`}
            >
              Acceptance{" "}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="sparator-block">
            <span className="hr-line" />
          </div>
          {status === 4 ? (
            <div>
              <div className="text-14-400-17 color-6866 cmb-18 cmt-16">
                Acceptance is under processing
              </div>
              <div className="cmb-16 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer">
                <span className="border-end cpe-10">View</span>
                <span className="">Download Acceptance Letter</span>
              </div>
            </div>
          ) : (
            <div className="w-100 cpt-20 cpb-20"></div>
          )}
        </div>

        {/* Registration */}
        <div className="d-flex justify-content-start align-items-center">
          <div
            className={`steps-block ${status >= 5 ? "active" : "not-active"}`}
          >
            5
          </div>
          <div>
            <div
              className={`text-16-500-20 ${
                status >= 5 ? "color-new-car" : "color-6866"
              }`}
            >
              Registration{" "}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          {status === 5 && (
            <div className="w-50">
              <div className="follow-ups-block border br-5 w-100 cmb-8">
                <div
                  className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                  onClick={() => {
                    setIsFollowUps(!isFollowUps);
                  }}
                >
                  <span>Follow Ups</span>
                  <i
                    className={`${
                      isFollowUps ? "bi bi-chevron-up" : "bi bi-chevron-down"
                    }`}
                    style={{ fontSize: "15px" }}
                  />
                </div>

                {isFollowUps && <FollowUpsDetails />}
              </div>

              <div className="text-14-400-17 color-6866 cmb-10 cmt-16">
                You have paid successfully !!
              </div>
              <div className="text-14-400-17 color-6866 cmb-18">
                Payment verification has been completed !!
              </div>

              <div className="cmb-16 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer">
                <span className="border-end cpe-10">View</span>
                <span className="">Download Pass</span>
              </div>
              <AbstractRegistrationPass />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default NewViewPaper;
